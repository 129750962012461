import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["lightbox"]

  connect() {
    this.lightbox = this.createLightbox()
    document.body.appendChild(this.lightbox)
  }

  createLightbox() {
    const subscriptionCode = this.element.dataset.lightboxSubscriptionCode;
    const lightbox = document.createElement("div")
    lightbox.classList.add("fixed", "inset-0", "flex", "items-center", "justify-center", "bg-black", "bg-opacity-50", "hidden", "z-50")
    lightbox.innerHTML = `
      <div class="relative py-4 px-8 text-base space-y-4 bg-gray-100 rounded-sm shadow-lgs text-gray-900 max-w-3xl z-50" data-action="click->lightbox#stopPropagation">
      
        <button data-action="click->lightbox#hide" class="absolute text-2xl top-2 right-4 text-gray-500 hover:text-gray-700">&times;</button>
        
        <p class="text-gray-900 border-b border-black text-xl pb-2 font-roboto-mono uppercase"><b>Suscripción anual: Accede a todo INGENIO</b></p>

        <p class="pt-4">Formación que te acompaña en tu desarrolo profesional. Una <b>tarifa plana anual</b> que te permite hacer cualquiera de nuestros Cursos y acceder a todas las Masterclasses de los expertos.</p>
        
        <p><b>Incluye:</b></p>

        <ul class="px-4 mb-12 pb-0 leading-relaxed">
          <li>✓ Todo el <a href="/cursos" class="underline text-blue">catálogo de cursos</a> a tu disposición. Accede cada mes al curso que elijas.</li>
          <li>✓ Acceso al archivo completo de <a href="/masterclasses" class="underline text-blue">Masterclasses</a> de los expertos.</li>
          <li>✓ Acceso a tu historial de cursos completados para consulta y repaso futuro.</li>
          <li>✓ Renovación anual. Cancela cuando quieras.</li>
        </ul>

        <div class="flex items-center justify-start pb-6">
          <a href="https://sso.teachable.com/secure/34166/checkout/1215129/suscripcion" class="hidden button-mini rounded-sm bg-white text-black hover:bg-black hover:text-white hover:opacity-100 border border-black mr-2">Mensual - 69€/mes</a>
          <a href="https://sso.teachable.com/secure/34166/checkout/1215130/suscripcion?coupon_code=${subscriptionCode}" class="button-mini rounded-sm bg-black text-white border border-black">Suscripción Anual - 690€/año</a>
        </div>

        </div>
    `
    lightbox.addEventListener("click", this.hide.bind(this))
    return lightbox
  }

  show() {
    this.lightbox.classList.remove("hidden")
  }

  hide() {
    this.lightbox.classList.add("hidden")
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
